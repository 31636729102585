import {
  Box,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { useTranslations } from "next-intl";
import { BiFastForward, BiHomeAlt } from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { SiSpringsecurity } from "react-icons/si";
import { BsCardChecklist, BsFillPersonFill } from "react-icons/bs";
const Feature = ({
  icon,
  feature,
  comingSoon,
}: {
  icon: any;
  feature: string;
  comingSoon?: boolean;
}) => {
  const t = useTranslations("Features.features");
  return (
    <Stack spacing={{ base: "4", md: "5" }}>
      <Square
        size={{ base: "10", md: "12" }}
        bg="accent"
        color="inverted"
        borderRadius="lg"
      >
        <Icon as={icon} boxSize={{ base: "5", md: "6" }} />
      </Square>
      <Stack spacing={{ base: "1", md: "2" }}>
        <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="medium">
          {t(`${feature}.name` as any)}
        </Text>
        <Text>{t(`${feature}.description` as any)}</Text>
      </Stack>
      {comingSoon && (
        <Tag alignSelf="end" size="md" variant="solid" colorScheme="green">
          {t("comingSoon")}
        </Tag>
      )}
    </Stack>
  );
};
export const Features = () => {
  const t = useTranslations("Features");
  return (
    <Box id="features" bg="white" as="section">
      <Heading as="h2" textAlign={["center", "left"]} size="lg" mb={16}>
        {t("subheader")}
      </Heading>
      <Container pb={16} px={16}>
        <Stack spacing={{ base: "12", md: "16" }}>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            columnGap={8}
            rowGap={{ base: 10, md: 16 }}
          >
            <Feature icon={BiFastForward} feature="efficient" />
            <Feature icon={BsCardChecklist} feature="list" />
            <Feature icon={SiSpringsecurity} feature="privacy" />
            <Feature icon={BiHomeAlt} feature="manage" />
            <Feature icon={FaFileInvoiceDollar} feature="invoice" />
            <Feature icon={BsFillPersonFill} feature="branding" />
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  );
};
