import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { SUBMIT_CTA_FINAL_SIGNUP_EVENT } from "services/analytics/events";
import { track } from "services/analytics";

export const CallToActionAccent = () => {
  const t = useTranslations("CallToActionAccent");
  return (
    <Box
      bg="brand.800"
      color="on-accent"
      px={{ base: "6", lg: "16" }}
      py={{ base: "10", lg: "16" }}
    >
      <Stack
        spacing="8"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        maxW="container.lg"
        margin="auto"
      >
        <Stack spacing="4">
          <Heading size="sm">{t("header")}</Heading>
          <Text
            color="on-accent-muted"
            fontSize={useBreakpointValue({ base: "lg", lg: "xl" })}
          >
            {t("subheader")}
          </Text>
        </Stack>
        <Stack
          spacing="3"
          direction={{ base: "column", sm: "row" }}
          justify={{ base: "start" }}
        >
          <Link href="sign-up">
            <Button
              onClick={() => track(SUBMIT_CTA_FINAL_SIGNUP_EVENT)}
              variant="primary"
              size="lg"
              fontWeight="bold"
            >
              {t("ctaBtn")}
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};
