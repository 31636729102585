import type { NextPage } from "next";
import Head from "next/head";
import { Box, Container } from "@chakra-ui/react";
import * as React from "react";
import { Footer } from "components/Footer";
import { Features } from "components/Features";
import { CallToActionAccent } from "components/CallToAction/Accent";
import { CallToActionCentered } from "components/CallToAction/Accent/Centered";
import { CountdownTimer } from "components/CountdownBanner";
import { HowItWorks } from "components/HowItWorks";
import { SiteNav } from "components/SiteNav";
import pick from "lodash.pick";
import dynamic from "next/dynamic";
import { LogoGrid } from "components/LogoGrid";

const DemoLabelNoSSR = dynamic<any>(() =>
  import("components/DemoLabel").then((mod) => mod.DemoLabel)
);

const Home: NextPage = () => {
  return (
    <Box as="section" bgColor="white">
      <SiteNav bg="white" />
      <Head>
        <title>Silkscan</title>
        <meta name="description" content="Silkscan" />
        <meta property="og:title" content="logo" />
        <meta property="og:image" content="logo_l.png" />
        <link rel="icon" href="/brand_icon.png" />
      </Head>

      <main style={{ backgroundColor: "white" }}>
        <Container
          maxW="container.xl"
          py={{ base: "4", md: "8" }}
          px={{ base: "0", md: 8 }}
        >
          <CallToActionCentered mb={20} />
          <HowItWorks mb={20} />
          <DemoLabelNoSSR />
          <LogoGrid />
          <Features />
        </Container>
        <CallToActionAccent />
      </main>
      <footer>
        <Footer />
      </footer>
    </Box>
  );
};

const namespaces = [
  "SiteNav",
  "Footer",
  "CountdownTimer",
  "CallToActionAccent",
  "Features",
  "HowItWorks",
  "LogoGrid",
  "CallToActionCentered",
  "DemoLabel",
];
export const getStaticProps = async (context: any) => {
  return {
    props: {
      messages: pick(
        (await import(`public/locales/${context.locale}/translation.json`))
          .default,
        namespaces
      ),
    },
  };
};

export default Home;
