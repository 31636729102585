import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { track } from "services/analytics";
import { SUBMIT_CTA_SIGNUP_EVENT } from "services/analytics/events";

export const CallToActionCentered = ({ ...styleProps }: any) => {
  const t = useTranslations("CallToActionCentered");
  return (
    <Box
      borderRadius={["none", "xl"]}
      as="section"
      p={{ base: 0, md: 20 }}
      textAlign="center"
      {...styleProps}
    >
      <Container py={{ base: "16", md: "24" }}>
        <Stack spacing={{ base: "8", md: "10" }}>
          <Stack spacing={{ base: "4", md: "5" }} align="center">
            <Heading as="h1" size="xl">
              {t("header")}
            </Heading>
            <Text
              color="on-accent-muteed"
              maxW="2xl"
              textAlign="center"
              fontSize="xl"
            >
              {t.rich("subheader", {
                bold: (children) => <b> {children} </b>,
              })}
            </Text>
          </Stack>
          <Stack
            spacing="3"
            direction={{ base: "column", sm: "row" }}
            justify="center"
          >
            <Link href="#learn-more">
              <Button size="lg">{t("learnMoreBtn")}</Button>
            </Link>
            <Link href="/sign-up">
              <Button
                onClick={() => track(SUBMIT_CTA_SIGNUP_EVENT)}
                variant="primary"
                size="lg"
                fontWeight="bold"
              >
                {t("ctaBtn")}
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
