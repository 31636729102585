import { Text, Box, Flex, Stack, Heading } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import howItWorks1 from "public/how-step-1.svg";
import howItWorks2 from "public/how-step-2.svg";
import howItWorks3 from "public/how-step-3.svg";
import howItWorks4 from "public/how-step-4.svg";
import howItWorks1ko from "public/how-step-1-ko.svg";
import Image from "next/image";

export const HowItWorks = ({ ...styleProps }: any) => {
  const router = useRouter();
  const { locale } = router;

  const t = useTranslations("HowItWorks");
  return (
    <Box as="section" id="learn-more" width="100%" {...styleProps}>
      <Heading
        as="h2"
        textAlign={["center", "left"]}
        size="lg"
        px={{ base: 4, md: 0 }}
      >
        {t("heading")}
      </Heading>
      <Stack direction={["column", "row"]} spacing={4} mt={6}>
        <Flex
          flex="1"
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Box mb={8} w="150px">
            <Image
              alt={t("header", { step: 1 })}
              src={locale === "ko" ? howItWorks1ko : howItWorks1}
            />
          </Box>
          <Box minH="100px">
            <Text m={4}> {t("stepOne", { step: 1 })}</Text>
          </Box>
        </Flex>
        <Flex
          flex="1"
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Box mb={8} w="150px">
            <Image alt={t("header", { step: 2 })} src={howItWorks2} />
          </Box>
          <Box minH="100px">
            <Text m={4}> {t("stepTwo", { step: 2 })}</Text>
          </Box>
        </Flex>
        <Flex
          flex="1"
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Box mb={8} w="150px">
            <Image alt={t("header", { step: 3 })} src={howItWorks3} />
          </Box>
          <Box minH="100px">
            <Text m={4}> {t("stepThree", { step: 3 })}</Text>
          </Box>
        </Flex>
        <Flex
          flex="1"
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Box mb={8} w="150px">
            <Image alt={t("header", { step: 4 })} src={howItWorks4} />
          </Box>
          <Box minH="100px">
            <Text m={4}> {t("stepFour", { step: 4 })}</Text>
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
};
