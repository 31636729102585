import { Box, Center, Container, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import Image from "next/image";
import { useTranslations } from "next-intl";

export const LogoGrid = () => {
  const t = useTranslations("LogoGrid");
  return (
    <Container py={{ base: "4", md: "16" }}>
      <Box
        bg="bg-surface"
        pb={{ base: "12", md: "16" }}
        px={{ base: "6", md: "16" }}
      >
        <Stack spacing="8">
          <Text
            fontWeight="medium"
            fontSize={{ base: "md", md: "lg" }}
            color="muted"
            textAlign="center"
          >
            {t("title")}
          </Text>
          <Stack
            direction={["column", "row"]}
            spacing={12}
            justify="center"
            alignItems="center"
          >
            {[
              { src: "/kiaf.png", width: "377px", height: "128px" },
              {
                src: "/artjeju.png",
                width: "496px",
                height: "114px",
              },
            ].map(({ src, width, height }, index) => (
              <Center key={index} maxW="12rem">
                <Image alt="artjeju" src={src} width={width} height={height} />
              </Center>
            ))}
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};
